<template>
  <div>
    <div data-test="content-map=address">
      {{ meta.address }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentMap',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
